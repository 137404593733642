/*
 * @Author       : your name
 * @Date         : 2020-12-08 15:48:34
 * @LastEditTime : 2020-12-08 18:32:54
 * @LastEditors  : Please set LastEditors
 * @Description  : In User Settings Edit
 * @FilePath     : /mingya/src/api/aboutUs.js
 */
import request from '@/helper/request'
export function getAboutUsIntroData() {
  return request({
    url: 'api/pages',
    method: 'get',
    params: {
      code: 'about'
    }
  })
}
export function getAboutUsMainBusiness() {
  return request({
    url: 'api/pages',
    method: 'get',
    params: {
      code: 'main_business'
    }
  })
}
export function getVideos() {
  return request({
    url: 'api/pages',
    method: 'get',
    params: {
      code: 'about_video'
    }
  })
}
export function getAboutUsCultureData() {
  return request({
    url: 'api/pages',
    method: 'get',
    params: {
      code: 'culture'
    }
  })
}
export function getAboutUsHonorData(data) {
  return request({
    url: 'api/events',
    method: 'get',
    params: data
  })
}
export function getNewsAndDutyData(data) {
  return request({
    url: 'api/news-and-duty-list',
    method: 'post',
    data: data
  })
}
export function getEventsGroup(data) {
  return request({
    url: 'api/events-group',
    method: 'post',
    data: data
  })
}
export function getEventDetail(data) {
  return request({
    url: 'api/event-detail',
    method: 'post',
    data: data
  })
}
export function getNewsAndDutyDetail(data) {
  return request({
    url: 'api/news-and-duty-detail',
    method: 'post',
    data: data
  })
}
