<template>
  <div class="title">
    <div class="title-name">{{title.name}}</div>
    <div class="en hightlight"><span>/</span> {{title.en_1}}</div>
    <div class="en">{{title.en_2}}</div>
  </div>
</template>

<script>
import { isMobile } from '@/helper/utils'
export default {
  props: {
    title: {
      default: {}
    }
  },
  data () {
    return {
      isMobile: isMobile()
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  height: 1.06rem;
  align-items: center;
  
  border-bottom: 1px solid #DCDCDC;
  .title-name {
    font-size: .3rem;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    &::after {
      width: 100%;
      height: 0.04rem;
      display: block;
      content: "";
      position: absolute;
      left: 0;
      bottom: -1px;
      background-color: #FE6917;
    }
  }
  .en {
    font-size: .16rem;
    color: #DCDCDC;
    margin-left: .4rem;
    letter-spacing: .22rem;
    &.hightlight {
      color: #FE6917;
    }
  }
}
</style>