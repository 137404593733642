<template>
  <div v-if="cultureData" :class="[isMobile ? 'mobile-about-culture' : '', 'about-culture']">
    <item-title v-if="!isMobile" :title="{name: '明亚文化', en_1: 'MINGYA', en_2: 'CULTURE'}" />
    <div class="culture-content">
      <div  class="logo">
        <img :src="cultureData[1].content" alt="">
      </div>
      <div class="logo-intro">
        <div class="title">{{cultureData[0].position}}</div>
        <div class="detail">
          <pre>{{cultureData[0].content}}</pre>
        </div>
      </div>
      <div class="slogan">
        <!-- <div class="title">
          <div class="bg">SLOGAN</div>
          <div class="title-content">
            <div class="cn">我的保险&emsp;我的生活</div>
            <div class="en">MY INSURANCE&emsp; MY LIFE</div>
          </div>
        </div> -->
        <!-- 顺序调整为：理念 愿景 口号 使命 司训 -->
        <div class="slogan-content">
          <div class="slogan-item">
            <div class="item-title">
              <div class="cn">理念</div>
              <div class="en">CONCEPT</div>
            </div>
            <div class="item-content">
              <pre>{{cultureData[2].content}}</pre>
            </div>
          </div>
          <div class="slogan-item">
            <div class="item-title">
              <div class="cn">愿景</div>
              <div class="en">VISION</div>
            </div>
            <div class="item-content">
              <pre>{{cultureData[3].content}}</pre>
            </div>
          </div>
          <div class="slogan-item">
            <div class="item-title">
              <div class="cn">口号</div>
              <div class="en">SLOGAN</div>
            </div>
            <div class="item-content">
              <pre>{{cultureData[4].content}}</pre>
            </div>
          </div>
          <div class="slogan-item">
            <div class="item-title">
              <div class="cn">使命</div>
              <div class="en">MISSION</div>
            </div>
            <div class="item-content">
              <pre>{{cultureData[5].content}}</pre>
            </div>
          </div>
          <div class="slogan-item">
            <div class="item-title">
              <div class="cn">司训</div>
              <div class="en">MOTTO</div>
            </div>
            <div class="item-content">
              <pre>{{cultureData[6].content}}</pre>
            </div>
          </div>
        
        

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemTitle from '@/components/aboutUs/itemTitle'
import { getAboutUsCultureData } from '@/api/aboutUs'
import { isMobile } from '@/helper/utils'
export default {
  components: {
    ItemTitle
  },
  data () {
    return {
      isMobile: isMobile(),
      cultureData: null
    }
  },
  created () {
    this.getCultrueData()
  },
  methods: {
    // 获取明亚文化数据
    async getCultrueData () {
      const res = await getAboutUsCultureData()
      this.cultureData = res.list
      this.$emit('loading', false)
    },
    // 获取明亚文化标题及slogan
    getCultureTitle(str){
      const r = str.split('_') 
      return r    
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-about-culture.about-culture {
  margin-left: 0;
  .culture-content {
    .logo {
      width: 100%;
      height: auto;
      margin-top: 0;
    }
    .slogan {
      margin-top: 0px;
      .title {
        line-height: normal;
        position: relative;
        .bg {
          color: #F4F4F4;
          font-size: 32px;
          padding-left: 1.04rem;
          letter-spacing: .2rem;
          font-weight: bold;
        }
        .title-content {
          position: absolute;
          left: 0;
          top: 15px;
          .cn {
            color: #FE6917;
            font-size: 14px;
            letter-spacing: .12rem;
          }
          .en {
            color: #FE6917;
            font-size: 14px;
            opacity: .5;
            margin-top: .1rem;
            letter-spacing: .03rem;
          }
        }
      }
      .slogan-content {
        display: block;
        flex-wrap: wrap;
        margin-top: 50px;
        .slogan-item {
          width: 100%;
          height: auto;
          margin-right: .4rem;
          margin-top: 0;
          margin-bottom: 30px;
          &:nth-child(even) {
            margin-right: 0;
          }
          &:last-child {
            margin-bottom: 0;
          }
          background-color: #FFF6F1;
          font-size: 12px;
          color: #222222;
          box-sizing: border-box;
          padding: 15px;
          position: relative;
          border-radius: .16rem;
          // line-height: .26rem;
          .item-content {
            pre {
              white-space: pre-wrap; /* css-3 */
              white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
              white-space: -pre-wrap; /* Opera 4-6 */
              white-space: -o-pre-wrap; /* Opera 7 */
              word-wrap: break-word; /* Internet Explorer 5.5+ */
              font-size: 12px;
              line-height: 22px;
              color: #222222;
              font-family: 'Microsoft YaHei', Arial, sans-serif;
              text-align: justify;
              margin-bottom: 0;
            }
          }
          &::after {
            content: "";
            display: block;
            // width: 75px;
            width: 48px;
            height: 14px;
            background-color: #FFDBC7;
            border-radius: 7px;
            position: absolute;
            left: 15px;
            top: -7px;
          }
          .item-content {
            padding: 0;
          }
          .item-title {
            position: absolute;
            left: 20px;
            display: flex;
            align-items: center;
            top: -15px;
            z-index: 10;
            left: 25px;
            .cn {
              font-size: 14px;
              font-weight: bold;
            }
            .en {
              font-size: 14px;
              color: #FE6917;
              margin-left: 15px;
            }
          }
        }
      }
    }
    .logo-intro {
      margin-top: 20px;
      .title {
        font-size: 16px;
        font-weight: bold;
      }
      .detail {
        margin-top: 0;
        pre {
          font-family: 'Microsoft YaHei', Arial, sans-serif;
          font-size: .16rem;
          color: #7D7D7D;
          line-height: 22px;
          margin-top: 10px;
          margin-bottom: 10px;
          text-align: justify;
          white-space: pre-wrap; /* css-3 */
          white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
          white-space: -pre-wrap; /* Opera 4-6 */
          white-space: -o-pre-wrap; /* Opera 7 */
          word-wrap: break-word; /* Internet Explorer 5.5+ */
        }
        font-size: 12px;
        color: #7D7D7D;
        line-height: 22px;
        // margin-top: 20px;
      }
    }
    
  }
}
.about-culture {  
  margin-left: 0.6rem;
  .culture-content {
    .logo {
      width: 5.67rem;
      height: 1.09rem;
      margin-top: 1rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .logo-intro {
      margin-top: 1rem;
      line-height: normal;
      .title {
        font-size: .36rem;
      }
      .detail {
        pre {
          font-family: 'Microsoft YaHei', Arial, sans-serif;
          font-size: .16rem;
          color: #7D7D7D;
          line-height: .34rem;
          margin-top: .4rem;
          text-align: justify;
          white-space: pre-wrap; /* css-3 */
          white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
          white-space: -pre-wrap; /* Opera 4-6 */
          white-space: -o-pre-wrap; /* Opera 7 */
          word-wrap: break-word; /* Internet Explorer 5.5+ */
        }
        font-size: .16rem;
        color: #7D7D7D;
        line-height: .34rem;
        margin-top: .4rem;
      }
    }
    .slogan {
      margin-top: .6rem;
      .title {
        line-height: normal;
        position: relative;
        .bg {
          color: #F4F4F4;
          font-size: 1.1rem;
          padding-left: 1.04rem;
          letter-spacing: .2rem;
          font-weight: bold;
        }
        .title-content {
          position: absolute;
          left: 0;
          top: .7rem;
          .cn {
            color: #FE6917;
            font-size: .36rem;
            letter-spacing: .12rem;
          }
          .en {
            color: #FE6917;
            font-size: .24rem;
            opacity: .5;
            margin-top: .1rem;
            letter-spacing: .03rem;
          }
        }
      }
      .slogan-content {
        display: flex;
        flex-wrap: wrap;
        margin-top: .7rem;
        .slogan-item {
          width: 5rem;
          height: 1.3rem;
          margin-right: .4rem;
          margin-top: .65rem;
          &:nth-child(even) {
            margin-right: 0;
          }
          background-color: #FFF6F1;
          font-size: .16rem;
          color: #222222;
          box-sizing: border-box;
          padding: .3rem;
          position: relative;
          border-radius: .16rem;
          line-height: .26rem;
          .item-content {
            pre {
              white-space: pre-wrap; /* css-3 */
              white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
              white-space: -pre-wrap; /* Opera 4-6 */
              white-space: -o-pre-wrap; /* Opera 7 */
              word-wrap: break-word; /* Internet Explorer 5.5+ */
              font-size: .16rem;
              color: #222222;
              font-family: 'Microsoft YaHei', Arial, sans-serif;
              text-align: justify;
            }
          }
          &::after {
            content: "";
            display: block;
            // width: 1.3rem;
            width: 0.75rem;
            height: .16rem;
            background-color: #FFDBC7;
            border-radius: .8rem;
            position: absolute;
            left: .3rem;
            top: -0.08rem;
          }
          .item-title {
            position: absolute;
            left: .42rem;
            display: flex;
            align-items: center;
            top: -0.25rem;
            z-index: 10;
            .cn {
              font-size: .26rem;
              font-weight: bold;
            }
            .en {
              font-size: .24rem;
              color: #FE6917;
              margin-left: .3rem;
            }
          }
        }
      }
    }
  }
}
</style>